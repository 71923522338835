import {
    trigger,
    animate,
    transition,
    style,
    query,
    state,
    stagger
  } from '@angular/animations';
  
  export const FadeAnimation = trigger('fadeAnimation', [
    // The '* => *' will trigger the animation to change between any two states
    transition('* => *', [
      query(
        ':enter',
        [style({ opacity: 0, right:-200 })],
        { optional: true }
      ),
      query(
        ':leave',        
        [style({ opacity: 1, right:0 }), animate('0.1s', style({ opacity: 0, right:-200 }))],
        { optional: true }
      ),
      query(
        ':enter',
        [style({ opacity: 0, right:-200 }), animate('0.1s', style({ opacity: 1, right:0 }))],
        { optional: true }
      )
    ])
  ]);

  export const DirectFade = trigger('directFade', [
    state('state1', style({
     opacity:0.75,     
     top:0
    })),
    state('state2', style({
     opacity:0,     
     top:'100%'
    })),
    transition('*=>state1', animate('300ms')),
    transition('*=>state2', animate('300ms'))
  ]);

  export const FullFade = trigger('fullFade', [
    state('state1', style({
     left:"-200px"    
    })),
    state('state2', style({
     left: "0px"   
    })),
    transition('*=>state1', animate('300ms ease-in')),
    transition('*=>state2', animate('300ms ease-out'))
  ]);
  
export const ListFade = trigger('listFade', [
  transition('* => *', [ // each time the binding value changes
    query(':leave', [
      stagger(100, [
        animate('0.2s', style({ opacity: 0 }))
      ])
    ], { optional: true }),
    query(':enter', [
      style({ opacity: 0 }),
      stagger(100, [
        animate('0.5s', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ])
  ]);

  export const SlideInFromLeft = trigger('slideInFromLeft', [
    transition('* => *', [ // each time the binding value changes
      query(':leave', [
        stagger(100, [
          animate('0.2s', style({ left: '-200px' }))
        ])
      ], { optional: true }),
      query(':enter', [
        style({ left: '-200px' }),
        stagger(100, [
          animate('0.2s', style({ left: '0px' }))
        ])
      ], { optional: true })
    ])
    ]);

  export const DropInFromTop = trigger('dropInFromTop',[   
    state('hiddenAbove',style({
      top:'-100%'
    })),
    state('droppedIn',style({
      top:'0px'
    })),
    transition('* => hiddenAbove', animate('300ms ease')),
    transition('* => droppedIn', animate('300ms ease'))
  ]);
  
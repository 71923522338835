import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { SlideInFromLeft, ListFade, FullFade } from './shared/animations';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ FullFade ]
})

export class AppComponent implements OnInit {
  title = 'SFPortfolio';
  menuState = "state1";

  backgroundState = "videoLoop";

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  constructor(private router:Router){
    router.events.subscribe((val) => {
      if(val instanceof NavigationStart){
        this.closeMenu();
      }
    });
  }

  ngOnInit(){        
    this.setUpVideoLoop();
  }

  // On activation of child components loaded in router outlet:
  onActivate(componentReference){
        
    // Listen for BG change emitters from child components:
    if(componentReference.changeBackgroundEmitter != undefined) {
      componentReference.changeBackgroundEmitter.subscribe(event => {

        if(event.command === "changeBG")
          this.changeBackground(event.arg)
       
      });
    }else{
      this.clearBackgroundAndPlayVideo()
    }
  }

  clearBackgroundAndPlayVideo(){
    
       // if video is stopped, then resume:
       let video: any;

       video = document.getElementById("main-vid-bg");

       if(video.paused){
         console.log("video is paused");
         this.setUpVideoLoop();
       }

       let allBackgrounds = document.getElementsByClassName("video-overlay active");

       for(var i = 0; i < allBackgrounds.length; i++){
         allBackgrounds[i].classList.remove("active");
       }

  }

  changeBackground(bgClassName: string){
    
    let allBackgrounds = document.getElementsByClassName("video-overlay active");

    //clear any other active bg:
    for(var i = 0; i < allBackgrounds.length; i++){
    
      if(allBackgrounds[i].classList.contains(bgClassName))
        continue;
      
      allBackgrounds[i].classList.remove("active");
    }

    document.getElementsByClassName(`video-overlay ${bgClassName}`)[0].classList.add("active");

    // activate the BG element in the command:
    setTimeout(()=>{
      this.stopVideoLoop();
    },900);
    
  }

  logAnimation(event: any){
    
  }
  
  onMenuClick(){
    
    let element = document.getElementById("mainMenuToggle");
    
    if (element.classList.contains("is-active")){
      element.classList.remove("is-active");
      this.menuState = "state1";
    }else {
      element.classList.add("is-active");
      this.menuState = "state2";
    }

  }

  closeMenu(){
    let element = document.getElementById("mainMenuToggle");
    element.classList.remove("is-active");

    this.menuState = "state1";
  }

  stopVideoLoop(){

    this.videoplayer.nativeElement.pause();
    
    this.backgroundState = "imageOnly";

  }  

  setUpVideoLoop(){
    
    this.videoplayer.nativeElement.muted = true;
    let playPromise = this.videoplayer.nativeElement.play();

    if(playPromise !== undefined){

      playPromise
        .then(()=>
          {
            console.log("video started");
          })
        .catch((e) => 
          {
            console.log(e);
          })

    }

  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ExamplesLandingComponent } from './examples-landing/examples-landing.component';
import { BarLineChartsComponent } from './examples/bar-line-charts/bar-line-charts.component';

const routes: Routes = [
  {path: "", component: HomeComponent},
  {path: "examples", component: ExamplesLandingComponent},
  {path: "examples/bar-and-line", component: BarLineChartsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';


interface YData {
    y:number;
}

@Component({
  selector: 'app-bar-line-charts',
  templateUrl: './bar-line-charts.component.html',
  styleUrls: ['./bar-line-charts.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BarLineChartsComponent implements OnInit {

  @Output() changeBackgroundEmitter = new EventEmitter<any>();

  getElementPadding: (object: any) => objectPadding;

  graphDataSet: Array<Array<YData>>;

  graphLine: any;

  constructor(private router: Router) { 

    let n = 21;

    this.graphDataSet = [
      d3.range(n).map(function(d){return {y: d3.randomUniform(1)()}}),
      d3.range(n).map(function(d){return {y: d3.randomUniform(1)()}}),
      d3.range(n).map(function(d){return {y: d3.randomUniform(1)()}}),
      d3.range(n).map(function(d){return {y: d3.randomUniform(1)()}})
    ]

    this.getElementPadding = (element: any): objectPadding => {
      
      const objectPadding: objectPadding = {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 0,
        paddingBottom: 0
      }

      return objectPadding;
    }   

  }

  // Refactor plan, create a line chart config object, put all d3 graph objects in there

  // create helper methods to manipulate the svg for each section of the graph (line,dot,x,y etc)

  ngOnInit() {
    
    // emit message to parnet to change from video to static ng:
    this.changeBackgroundEmitter.emit(
      {
        command: "changeBG",
        arg: "article-image"
      }
    );
    
    this.drawGraph();
    
    //window resize event:
    let updateWindow = () => {
      var s = d3.selectAll('svg');
      s = s.remove();
      this.drawGraph();
    }

    let resizeTimer; 

    window.onresize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        
        updateWindow();

      }, 500)
    };

  }
  drawGraph(datasetIndex = 0){
    
    //configure graph:
    let graphParent = document.getElementById("chartContainer1");

    let graphParentPadding = this.getElementPadding(graphParent);
    
    var margin = {top:50, right: 50, bottom: 50, left:50},
        width = graphParent.clientWidth - (margin.right + margin.left) - (graphParentPadding.paddingLeft + graphParentPadding.paddingRight),
        height = graphParent.clientHeight - (margin.top + margin.bottom) - (graphParentPadding.paddingTop + graphParentPadding.paddingBottom);
    
    var svg =  d3.select("#chartContainer1")
      .append("svg") // creates a new SVG element
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", "article-chart--dark")
      .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top +")");

      var n = 21;

      var xScale = d3.scaleLinear()
        .domain([0, n-1])
        .range([width, 0]);
      
      var yScale = d3.scaleLinear()
        .domain([0,1])
        .range([height, 0]);

      
      this.graphLine = d3.line()
        .x(function (d,i){ return xScale(i)})
        .y(function(d:any,i){ return yScale(d.y)})
        .curve(d3.curveMonotoneX)


      var line: any;

      line = d3.line()
        .x(function (d,i){ return xScale(i)})
        .y(function(d:any,i){return yScale(d.y)})
        .curve(d3.curveMonotoneX)

      var dataset = d3.range(n).map(function(d){return {y: d3.randomUniform(1)()}})

      // create x axis in group tag

      svg.append("g")
        .attr("class", "x-axis")
        .attr("transform", "translate(0, "+ height +")")
        .call(d3.axisBottom(xScale));

      // create y axis in group tag

      svg.append("g")
        .attr("class", "y-axis")
        .call(d3.axisLeft(yScale))

      // append path and bind data:

      svg.append("path")

        .datum(this.graphDataSet[datasetIndex])
        .attr("class", "line")
        .attr("d", this.graphLine)

      svg.selectAll(".dot")
        .data(this.graphDataSet[0])

        .datum(dataset)
        .attr("class", "line")
        .attr("d", line)

      svg.selectAll(".dot")
        .data(dataset)

        .enter().append("circle")
          .attr("class", "dot")
          .attr("cx", function(d,i){return xScale(i)})
          .attr("cy", function(d:any,i){return yScale(d.y)})
          .attr("r",5)
            .on("mouseover", function(a,b,c){
              //this.attr("class", "focus");
            })
            .on("mouseout", function(){})
  }

  changeGraphData(){

    // the only two dom elements that require updating are the line and dots

    // Set the line variable to the new dataset

    // set the dot variable to the new dataset


    // redraw graph (brutal)

    // clear line and dots and only redraw those parts.
    
  }

}

interface objectPadding {
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  paddingBottom: number;
}

interface objectPadding {
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  paddingBottom: number;
}
